<template>
    <v-container fluid>
        <v-row dense>
            <v-col class="inspiration" v-for="inspiration in sortedInspirations" :key="inspiration.id" cols="3" md="4" lg="3" xl="2">
                <v-card class="inspiration__item pa-3 ma-3 transition-swing" :elevation="inspiration.selected ? 12 : 2">
                    <div class="color-list">
                        <!-- Let op: selected kan niet met "isSelected" bepaald worden al is de code identiek; de klasse wordt dan niet reactief -->
                        <div v-for="option in inspiration.files" :key="option.id" :class="{ active: isActiveOption(inspiration, option), selected: selection.findIndex(selected => selected.id === option.id) > -1 }">
                            <v-img @click="activateOption(inspiration, option)" :src="option.thumb" :lazy-src="option.lazy"/>
                        </div>
                    </div>
                    <div class="item-image" @click="toggleSelection(activeOptions[inspiration.id])">
                        <v-img :src="activeOptions[inspiration.id].thumb" :lazy-src="activeOptions[inspiration.id].lazy" :class="{zoom: zoomed}"/>
                    </div>
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div class="ma-3 ml-3">
                            <v-card-title class="overline inspiration__title">
                                <div v-html="inspiration.name"></div>
                                <div v-html="activeOptions[inspiration.id].meta.brickColor"></div>
                            </v-card-title>
                        </div>
                        <v-card-actions @click="toggleSelection(activeOptions[inspiration.id])">
                            <v-btn v-if="isSelected(activeOptions[inspiration.id])" outlined icon tile color="green mt-1 add-btn">
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                            <v-btn v-else outlined icon tile color="black" class="mt-1 add-btn">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue';
import { formatDateTime } from '@/utils/vue-filters';

export default {
    name: 'InspirationalDesignListCards',

    components: {},

    filters: {
        formatDateTime: formatDateTime,
    },

    props: {
        displayFirst: {
            type: String,
            default: '',
        },
        zoomed: {
            type: Boolean,
            default: false,
        },
        inspirations: {
            type: Array,
            required: true,
        },
        value: {
            type: Array,
            required: false,
        },
    },

    data: () => ({
        activeOptions: {},
        selection: [],
    }),

    computed: {
        /**
         * Geef de inspiratielijst terug, maar gesorteert met de gevraagde kleur als eerste
         */
        sortedInspirations () {
            if (this.displayFirst === '') {
                return this.inspirations;
            }

            const inspirations = [...this.inspirations];
            inspirations.forEach(inspiration => {
                inspiration.files = [...inspiration.files];
                inspiration.files.sort((a, b) => {
                    if (a.meta.brickColor === this.displayFirst) {
                        return -1;
                    }
                    if (b.meta.brickColor === this.displayFirst) {
                        return 1;
                    }
                    return 0;
                });
                return inspiration;
            });

            this.activeOptions = {};
            this.updateActiveOptions(inspirations);

            return inspirations;
        },
    },

    watch: {
        value (newValue) {
            this.selection = newValue || [];
        },

        selection (newValue) {
            this.$emit('input', newValue);
        },

        inspirations (newValue) {
            // TODO: deze toewijzing van de selectie met `this.value` is nodig omdat anders de waarde verloren gaat. Uitzoeken waarom!
            this.selection = this.value || [];

            this.updateActiveOptions(newValue);
        },
    },

    created () {
        this.updateActiveOptions(this.inspirations);
    },

    methods: {
        /**
         * Bepaal index in selectie-array van een inspiratie-optie
         * @param {Object} option
         * @returns {Number}
         */
        getSelectedIndex (option) {
            return this.selection.findIndex(selected => selected.id === option.id);
        },

        /**
         * Bepaal of de gevraagde optie is geselecteerd door de gebruiker
         * @param {Object} option
         * @returns {boolean}
         */
        isSelected (option) {
            return this.getSelectedIndex(option) > -1;
        },

        /**
         * Toggle de selectie van de optie (wel/niet) meenemen als inspiratie
         * @param {Object} option
         * @returns {boolean}
         */
        toggleSelection (option) {
            const selectedIndex = this.getSelectedIndex(option);

            // "Niet geselecteerd" is index -1
            if (selectedIndex === -1) {
                this.selection.push(option);
            } else {
                this.selection.splice(selectedIndex, 1);
            }
        },

        /**
         * Werk de lijst bij waarin de actieve beelden staan. Kies hierbij
         * standaard het eerste beeld in de lijst.
         */
        updateActiveOptions (inspirations) {
            inspirations.forEach(inspiration => {
                if (typeof this.activeOptions[inspiration.id] === 'undefined') {
                    Vue.set(this.activeOptions, inspiration.id, inspiration.files[0]);
                }
            });
        },

        /**
         * Markeer een optie als actief voor de gewenste inspiratie
         * @param {Object} inspiration
         * @param {Object} option
         * @returns {boolean}
         */
        activateOption (inspiration, option) {
            Vue.set(this.activeOptions, inspiration.id, option);
        },

        /**
         * Bepaal of de gevraagde optie actief is
         * @param {Object} inspiration
         * @param {Object} option
         * @returns {boolean}
         */
        isActiveOption (inspiration, option) {
            return this.activeOptions[inspiration.id].id === option.id;
        },
    }
};
</script>

<style scoped lang="scss">
.zoom {
    ::v-deep .v-image__image {
        background-size: 200% !important;
    }
}

.inspiration {
    &__item {
        height: calc(100% - 20px);
        padding-bottom: 0 !important;
    }

    .add-btn {
        transform: translateX(8px);
    }

    &__title {
        cursor: pointer;
        padding: 0;
        margin-left: -8px;
        line-height: 1.1rem;

        & > div {
            width: 100%;

            &:last-of-type {
                color: var(--v-primary-base);
            }
        }
    }
}

.color-list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 4px;
    margin-bottom: 4px;

    & > div {
        width: 100%;
        overflow: hidden;
    }

    .selected {
        outline: 2px solid #4CAF50;
        margin: 1px;
        width: calc(100% - 2px);
        border: 2px solid white;
        position: relative;

        &:after {
            content: "\F012C";
            position: absolute;
            z-index: 2;
            width: 24px;
            height: 24px;
            font-size: 0.5rem;
            top: calc(50% - 12px);
            left: calc(50% - 12px);
            pointer-events: none;
            text-shadow: 0 0 5px black;
            color: #5bd460;
            font: normal normal normal 24px/1 "Material Design Icons";
        }
    }

    .active {
        outline: 1px solid black !important;
        border: 2px solid white;
    }
}

.item-image {
    cursor: pointer;
}
</style>
