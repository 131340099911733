var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"px-12 py-9",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-8 py-4 mt-12 mb-4",attrs:{"dark":"","outlined":"","tile":""}},[_c('v-card-title',{staticClass:"tiemposText ml-0 pl-0"},[_c('v-icon',{staticClass:"mr-5",attrs:{"x-large":"","color":"white"}},[_vm._v("mdi-account-group-outline")]),_c('h1',{staticClass:"text-h1 mb-2"},[_vm._v(_vm._s(_vm.$t('moodboard.heading')))]),_vm._v(" "+_vm._s(_vm.$t('workplace.explanation'))+" ")],1)],1),_c('moodboard-list',{attrs:{"moodboard":_vm.workplace,"user":_vm.user,"item-heading":_vm.$t('moodboard.label_virtual_workplace')},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var moodboard = ref.moodboard;
var user = ref.user;
return [_c('v-card',{staticClass:"pa-0",attrs:{"flat":"","color":"transparent"}},[_c('v-card-text',{staticClass:"tiemposText px-0 py-0"},[_vm._v(_vm._s(_vm.$t('moodboard.heading_add')))]),_c('v-card-text',{staticClass:"px-0 pb-0"},[_c('moodboard-add-files-entry-dialog',{attrs:{"moodboard":moodboard,"user":user},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 mb-3 boxed-icon",attrs:{"color":"primary","tile":"","elevation":"0","disabled":!moodboard.$ref}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('moodboard.add_files'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-plus-outline")])],1)]}}],null,true)}),_c('moodboard-add-links-entry-dialog',{attrs:{"moodboard":moodboard,"user":user},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 mb-3 boxed-icon",attrs:{"color":"primary","tile":"","elevation":"0","disabled":!moodboard.$ref}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('moodboard.add_links'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-link-plus")])],1)]}}],null,true)}),_c('inspiration-picker-dialog',{attrs:{"moodboard":moodboard},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 mb-3 boxed-icon",attrs:{"color":"primary","tile":"","outlined":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('inspiration.pick_a_design'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-eye-plus-outline")])],1)]}}],null,true)})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }