import { firestore } from 'firebase';
import { deleteFolder, uploadFiles } from '@/firebase/storage';
import { newId } from '@/firebase';

export const refInspirations = firestore().collection('inspirations');

/**
 * Maak een template voor een inspiratie-item.
 * @param {String} name
 * @param {Object} meta - De gegevens over dit item die bijvoorbeeld doorzoekbaar zijn en/of zichtbaarheid kunnen bepalen
 * @param {Object} user - Gebruikersobject met uid en displayName properties
 * @param {Array} files - Bestanden met impressies
 * @returns {{uid: *, meta: {}, name: *, timestamp: firebase.firestore.FieldValue}}
 */
const createInspiration = ({name, meta = {}, user, files}) => {
    if (!name || !user) {
        throw 'Arguments name and user are required to creating an inspirational item';
    }

    const inspiration = {
        name,
        ...meta,
        uid: user.uid,
        timestamp: firestore.FieldValue.serverTimestamp(),
    };

    if (files) {
        inspiration.files = files;
    }

    return inspiration;
};

/**
 * Maak een inspiratie-item aan in Firestore en geef een referentie terug
 * @param {Object} inspiration
 * @returns {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>}
 */
const createFirestoreInspiration = inspiration => {
    return refInspirations.add(inspiration);
};

/**
 * Haalt snapshot op indien document bestaat, anders false
 * @param docId
 * @return {Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>|boolean>}
 */
export const getFirestoreInspirationSnapshot = async docId => {
    const docInspiration = refInspirations.doc(docId);

    const snapInspiration = await docInspiration.get();
    if (!snapInspiration.exists) {
        return false;
    }

    return snapInspiration;
};

/**
 * Update een inspiratie-item
 * @param docId
 * @param data
 * @return {Promise<void>}
 */
export const updateFirestoreInspiration = async (docId, data) => {
    const refInspiration = refInspirations.doc(docId);
    return refInspiration.update({...data})
        .then(function () {
            console.log('Document successfully updated!');
        })
        .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error);
        });
};

/**
 * Voegt de bestanden voor het gevraagde item toe aan de storage.
 * Thumbnails zijn hier iets groter dan standaard vanwege de meest getoonde weergave.
 * @param {String} inspirationId
 * @param {Array} files
 * @return {Promise<[]>}
 */
const uploadInspirationFiles = async (inspirationId, files) => uploadFiles(`inspirations/${inspirationId}`, files, {thumbSize: 500});

/**
 * Voeg een entry toe aan het inspiratie-item.
 * Een entry bestaat uit een naam, bestanden, user info en een timestamp
 * @param {String} name - Naam van deze inspiratie
 * @param {Array} files - De lijst met alle afbeeldingen die bij dit item horen
 * @param {Object} meta - De gegevens over dit item die bijvoorbeeld doorzoekbaar zijn en/of zichtbaarheid kunnen bepalen
 * @param {Object} user - Gebruikersobject met uid en displayName properties
 * @return {Boolean|Promise<DocumentReference>} - Referentie naar het nieuwe inspiratie-item
 */
export const uploadFilesAndAddFirestoreInspiration = async (name, files = [], meta = {}, user) => {
    const docInspiration = await createFirestoreInspiration(createInspiration({name, meta, user}));
    const inspirationId = docInspiration.id;
    const fileEntries = await uploadInspirationFiles(inspirationId, files);
    await updateFirestoreInspiration(docInspiration.id, {files: fileEntries});

    return docInspiration;
};

/**
 * Voeg een entry toe aan het inspiratie-item.
 * Een entry bestaat uit een naam, bestanden, user info en een timestamp
 * @param {String} inspirationId - Id van bestaand inspiratie-item
 * @param {String} name - Naam van deze inspiratie
 * @param {Array} files - De lijst met alle afbeeldingen die bij dit item horen
 * @param {Object} meta - De gegevens over dit item die bijvoorbeeld doorzoekbaar zijn en/of zichtbaarheid kunnen bepalen
 * @return {Boolean|Promise<DocumentReference>} - Referentie naar het nieuwe inspiratie-item
 */
export const uploadFilesAndUpdateFirestoreInspiration = async (inspirationId, name, files = [], meta = {}) => {
    const docInspiration = refInspirations.doc(inspirationId);
    const snapInspiration = await docInspiration.get();
    if (!snapInspiration.exists) {
        return false;
    }

    // Let op: verwijdert geen bestanden uit inspiratiebeelden-database. Dat kan alleen handmatig of door verwijderen gehele item.
    const fileEntries = await uploadInspirationFiles(inspirationId, files);
    await updateFirestoreInspiration(docInspiration.id, {name, ...meta, files: fileEntries, timestamp: firestore.FieldValue.serverTimestamp()});

    return docInspiration;
};

/**
 * Verwijder een inspiratie-item en de bijbehorende bestanden
 * @param {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} refInspiration
 * @return {Promise<>}
 */
export const removeFirestoreInspirationsAndRemoveFiles = async refInspiration => {
    // Duck-typing voor een DocumentReference zodat we het pad en een verwijder-functie hebben
    if (!refInspiration.path || typeof refInspiration.delete !== 'function') {
        return Promise.reject('Pad naar inspiratiebeeld of functie "delete" ontbreekt');
    }

    await deleteFolder(refInspiration.path);
    return refInspiration.delete();
};
