<template>
    <div class="moodboard-list" v-bind="$attrs">
        <design-preview-dialog v-model="showDesignPreview" :design="currentDesign"><span></span></design-preview-dialog>
        <slot name="actions" :moodboard="moodboard" :user="user"/>
        <v-container v-for="(entry, i) in moodboard.entries.slice().reverse()" :key="i"
                     class="ma-0 px-8 my-10 moodboard-list__item-container" :class="entry.byDesigner ? 'moodboard-list__item-container--by-designer' : ''">
            <v-row>
                <v-col cols="6" class="pt-0">
                    <div class="overline overline-dark" v-if="entry.heading || itemHeading">{{ entry.heading || itemHeading }}</div>
                </v-col>

                <v-col cols="6" class="px-3 pa-0">
                    <div class="overline float-right"><span class="overline-dark">{{ entry.created | formatDate }}</span><span v-if="entry.name"> - {{ entry.name }}</span></div>
                </v-col>
            </v-row>
            <v-row v-if="entry.design" class="moodboard-list__item-design">
                <v-col>
                    <v-img v-if="entry.files[0]" :src="entry.files[0].thumb" :lazy-src="entry.files[0].lazy" aspect-ratio="1.3" max-width="300" class="grey lighten-2 "
                           @click="previewDesign(entry.design)">
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"/>
                            </v-row>
                        </template>
                    </v-img>
                    <v-btn v-else @click="previewDesign(entry.design)">{{ $t('moodboard.preview_3d_design') }}</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="!entry.design && entry.files.length">
                <v-col v-for="(file, i) in entry.files.filter(f => $isImageType(f.type))" :key="i"
                       class="d-flex child-flex " cols="3">
                    <v-img :src="file.thumb || file.url" :lazy-src="file.lazy || file.thumb" aspect-ratio="1.2"
                           class="grey lighten-2" @click="$popupImage(file)">
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"/>
                            </v-row>
                        </template>
                    </v-img>
                </v-col>
            </v-row>
            <v-row v-if="!entry.design && entry.files.length">
                <v-col>
                    <v-btn v-for="(file, i) in entry.files.filter(f => !$isImageType(f.type))" :key="i"
                           class="boxed-icon mb-1" @click="$downloadFile(file)" outlined block>
                        <v-icon left>mdi-file-download-outline</v-icon>
                        {{ file.title }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="!entry.design && entry.links.length">
                <v-col>
                    <v-btn v-for="(link, i) in entry.links" :key="i" class="boxed-icon mb-1" @click="$openUrl(link)"
                           outlined block :title="$compileUrl(link.url)">
                        <v-icon left>mdi-link-variant</v-icon>
                        {{ link.title || link.url }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="entry.comment">
                <v-col>
                    <div class="tiemposText tiemposText--bold">{{ entry.comment }}</div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { formatDate } from '@/utils/vue-filters';
import DesignPreviewDialog from '@/components/dialogs/DesignPreviewDialog';

export default {
    name: 'MoodboardList',

    components: {DesignPreviewDialog},

    props: {
        moodboard: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        itemHeading: {
            type: String,
            default: '',
        },
    },

    data: () => ({
        currentDesign: '/designs/null',
        showDesignPreview: false,
    }),

    filters: {
        formatDate: formatDate,
    },

    methods: {
        previewDesign (designPath) {
            this.currentDesign = designPath;
            this.showDesignPreview = true;
        }
    }
};
</script>

<style scoped lang="scss">
.v-image__image {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.overline-dark {
    color: #999;
}

.moodboard-list {
    &__item-container {
        background-color: rgba(0, 0, 0, 0.36);
        box-shadow: 0 0 60px rgba(155, 155, 155, 0.1);

        &--by-designer {
            background-color: rgba(255, 255, 255, 0.08);
            box-shadow: 0 0 60px rgba(0, 0, 0, 0.3);
        }

        &--concepts {
            background-color: rgba(255, 255, 255, 0.5);
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
            color: black;

            .overline-big {
                font-size: 1.1rem !important;
            }

            .overline-dark {
                color: white;
            }
        }
    }

    &__item-design {
        ::v-deep .v-image {
            cursor: pointer;
        }
    }
}
</style>
