<template>
    <v-dialog v-model="isVisible" light max-width="950" class="design-preview-dialog">
        <template #activator="{ on, attrs }">
            <slot :attrs="attrs" :on="on">
                <v-btn v-bind="attrs" v-on="on" color="primary" class="boxed-icon" tile elevation="0">
                    {{ $t('global.open') }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card dark>
            <v-card-title class="headline primary">{{ $t('design_preview.heading') }}</v-card-title>
            <the-previewer v-if="snapDesign" :kiosk="true" :design-list="snapDesign ? [snapDesign] : []"/>
            <div v-else class="design-preview-dialog__loading-placeholder background-gradient background-gradient--sharp-halve"></div>
            <v-btn color="primary" class="design-preview-dialog__close-btn boxed-icon pr-0 mr-4 my-2" tiles elevation="0" @click="isVisible = false">
                {{ $t('global.close') }}
                <v-icon right>mdi-close</v-icon>
            </v-btn>
        </v-card>
    </v-dialog>
</template>

<script>
import { firestore } from 'firebase';
import { toggleable } from '@/mixins/toggleable';
import ThePreviewer from '@/components/ThePreviewer';
import { startPreview, stopPreview } from '@/utils/design/preview';

export default {
    name: 'DesignPreviewDialog',

    components: {ThePreviewer},

    mixins: [toggleable],

    props: {
        design: {
            type: String,
            required: true,
            validator: val => val.indexOf('/designs/') > -1,
        }
    },

    data: () => ({
        currentDesignPath: '',
        snapDesign: null,
    }),

    watch: {
        async isVisible () {
            if (this.design !== this.currentDesignPath) {
                this.snapDesign = null;
            }

            if (!this.isVisible) {
                this.snapDesign = null;
                return stopPreview();
            }

            if (this.snapDesign) {
                return startPreview();
            }

            const refDesign = firestore().doc(this.design);
            this.snapDesign = await refDesign.get();
        }
    }
};
</script>

<style scoped lang="scss">
.design-preview-dialog {
    &__close-btn {
        position: absolute;
        right: 20px;
        bottom: 10px;
        z-index: 3;
    }

    &__loading-placeholder {
        height: calc(100vh - 172px);
    }
}
</style>
