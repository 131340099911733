<template>
    <v-dialog v-model="isVisible" persistent light max-width="650">
        <template #activator="{ on, attrs }">
            <slot :attrs="attrs" :on="on">
                <v-btn v-bind="attrs" v-on="on" color="primary" class="float-right boxed-icon" tile small elevation="0">
                    {{ $t('global.create') }}
                    <v-icon right>mdi-file-plus</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card>
            <v-card-title class="headline primary">{{ $t('moodboard_add_entry.headline_files') }}</v-card-title>
            <v-card-text class="tiemposText mt-4">{{ $t('moodboard_add_entry.explanation_files') }}</v-card-text>
            <v-form ref="form" v-model="formValid">
                <v-card-text light class="mx-0">
                    <v-list>
                        <v-list-item class="pa-1 pr-2" v-for="file in files" :key="file.id">
                            <v-file-input v-model="file.value" truncate-length="50" show-size :label="$t('upload.upload_file')" :placeholder="$t('upload.upload_file_placeholder')" @change="changeFile" :rules="uploadRules"/>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-text class="mt-0">
                    <v-textarea :placeholder="$t('moodboard_add_entry.comment_placeholder')" v-model="comment" outlined/>
                </v-card-text>
            </v-form>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="mr-4 mb-2" @click="isVisible = false">{{ $t('global.cancel') }}</v-btn>
                <v-btn :disabled="!valuesEntered || !formValid" color="primary" class="boxed-icon pr-0 mr-4 mb-2" tiles elevation="0" @click="addMoodboardEntry" :loading="isUploading">
                    {{ $t('global.create') }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { uploadFilesAndAddFirestoreMoodboardEntry } from '@/firebase/firestore/projects/moodboards';
import { toggleable } from '@/mixins/toggleable';
import { isImageType, restrictUploadSizeTo, RULES_URL_VALIDATE } from '@/utils';
import { touchFirestoreProject } from '@/firebase/firestore/projects';

export default {
    name: 'MoodboardAddFilesEntryDialog',

    mixins: [toggleable],

    props: {
        moodboard: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },

    watch: {
        values () {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        },

        isVisible () {
            // De $ref moet altijd bestaan. Bij voorkeur afdwingen met v-if="xxx.$ref" of :disabled="!xxx.$ref"
            // Hier het dialoogvenster hier sluiten is niet mogelijk omdat dat een lus veroorzaakt met deze functie zelf.
            if (!this.moodboard.$ref) {
                this.$store.dispatch('statusMessage/error', this.$t('moodboard_add_entry.error_on_open'));
            }
        },
    },

    data: () => ({
        formValid: null,
        isUploading: false,
        comment: '',
        files: [],
        uploadRules: [
            file => isImageType(file?.type)
                ? restrictUploadSizeTo(10)[0](file)
                : restrictUploadSizeTo(250)[0](file),
        ],
    }),

    computed: {
        /**
         * Schakel verstuurknop in zodra er files/links toegevoegd zijn (lengte van een lijst is dan groter dan 1)
         * @return {boolean}
         */
        valuesEntered () {
            return !!this.files.length;
        },
    },

    created () {
        this.resetForm();
        this.updateFilesList();
    },

    methods: {
        resetForm () {
            this.isUploading = false;
            this.comment = '';
            this.files = [];
        },

        isImageType (type) {
            return isImageType(type);
        },

        /**
         * Change-event voor file-pickers
         */
        changeFile () {
            this.$nextTick(() => {
                this.updateFilesList();
            });
        },

        /**
         * Update de lijst met bestanden die geuploadet moeten worden
         * - Haal lege items uit de lijst
         * - Voegt een nieuw leeg item toe aan het einde
         */
        updateFilesList () {
            // Lege files verwijderen
            if (this.files.length) {
                for (let i = this.files.length - 1; i >= 0; i--) {
                    if (!this.files[i].value) {
                        this.files.splice(i, 1);
                    }
                }
            }

            this.files.push({
                id: Math.round(Math.random() * 1000000000),
                value: null,
            });
        },

        /**
         * Voeg een rij toe aan het moodboard
         */
        async addMoodboardEntry () {
            this.isUploading = true;

            // Haal overbodige info uit de data en verwijder lege elementen
            const files = this.files.map(file => file.value).filter(notEmpty => notEmpty);

            await uploadFilesAndAddFirestoreMoodboardEntry(this.moodboard.$ref, {
                comment: this.comment,
                files,
                user: this.user,
            });

            this.resetForm();
            this.updateFilesList();

            const ignorePromise = touchFirestoreProject(this.moodboard.project);

            this.isVisible = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables';

.v-list-item:nth-of-type(even) {
    background-color: $colors-grey-lighten5;
}
</style>
