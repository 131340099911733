<template>
    <v-dialog v-model="isVisible" light max-width="90%">
        <template #activator="{ on, attrs }">
            <slot :attrs="attrs" :on="on">
                <v-btn v-bind="attrs" v-on="on" color="primary" class="boxed-icon" tile elevation="0">
                    {{ $t('inspiration.pick_a_design') }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card>
            <v-card-title class="headline primary">{{ $t('inspiration.headline') }}</v-card-title>
            <v-container fluid>
                <v-row dense>
                    <v-col v-if="designs.length" cols="10" lg="10" md="9">
                        <inspirational-design-list-cards :inspirations="designs" v-model="selection" :zoomed="zoom" :display-first="color"/>
                        <v-progress-circular v-if="!loadedAll && designs.length" v-intersect="loadInspirations" indeterminate color="primary"/>
                    </v-col>
                    <v-col v-else cols="10" lg="10" md="9">
                        {{ $t('inspiration.no_items_found') }}
                    </v-col>
                    <v-col cols="2" lg="2" md="3">
                        <div style="position: sticky; top: 30px;">
                            <div class="overline">{{ $t('inspiration.headline_display') }}</div>
                            <v-switch v-model="zoom" :label="$t('inspiration.label_zoom')"/>
                            <v-btn outlined tile x-small color="grey" class="float-right mt-2" @click="resetSearch">
                                {{ $t('global.reset') }}
                                <v-icon small right>mdi-reload</v-icon>
                            </v-btn>
                            <div class="overline mb-6">{{ $t('inspiration.headline_filters') }}</div>
                            <v-select v-model="color" :items="colorOrder" :label="$t('inspiration.label_color')" outlined/>
                            <v-select v-model="surface" :items="surfaceFilters" :label="$t('inspiration.label_surface')" outlined/>
                            <v-select v-model="collection" :items="collectionFilters" :label="$t('inspiration.label_collection')" outlined/>
                            <div v-if="collection" class="extra-select">
                                <v-icon>mdi-subdirectory-arrow-right</v-icon>
                                <v-select v-model="subcollection" :items="subcollectionFilters" :label="$t('inspiration.label_subcollection')" outlined :disabled="subcollectionFilters.length <= 1"/>
                            </div>
                            <v-divider class="mb-4 mt-2"/>
                            <div v-if="selection.length">
                                <div class="overline">{{ $t('inspiration.headline_selected') }}</div>
                                <div class="color-list">
                                    <div v-for="option in selection">
                                        <v-img style="width: 100%" :src="option.thumb" :lazy-src="option.lazy"/>
                                    </div>
                                </div>
                                <v-divider class="mb-4 mt-2"/>
                            </div>

                            <v-textarea v-model="comment" outlined rows="3" label="Comments or explanation"/>
                            <v-btn color="primary" class="boxed-icon pr-0 mr-4 mb-2 float-right" tiles elevation="0" @click="addSelectionToMoodboard" :disabled="selection.length === 0">
                                {{ $t('global.create') }}
                                <v-icon right>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn tile outlined color="black" class="mb-2 pa-0 px-2" @click="isVisible = false">{{ $t('global.cancel') }}</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider/>
            <v-card-actions></v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { toggleable } from '@/mixins/toggleable';
import { refInspirations } from '@/firebase/firestore/inspirations';
import InspirationalDesignListCards from '@/components/InspirationalDesignListCards';
import { addFirestoreMoodboardEntry } from '@/firebase/firestore/projects/moodboards';
import { createFileEntry } from '@/firebase/storage';
import { BRICK_COLORS, BRICK_COLLECTIONS, BRICK_SURFACES } from '@/utils';

export default {
    name: 'InspirationPickerDialog',

    mixins: [toggleable],

    components: {InspirationalDesignListCards},

    props: {
        moodboard: {
            type: Object,
            required: true,
        },
    },

    data () {
        return {
            colorOrder: [{text: this.$t('global.choose'), value: ''}, ...BRICK_COLORS, 'design'],
            collectionFilters: [{text: this.$t('global.show_all'), value: ''}, ...BRICK_COLLECTIONS.map(collection => collection.name)],
            subcollectionFilters: [{text: this.$t('global.show_all'), value: ''}],
            surfaceFilters: [{text: this.$t('global.show_all'), value: ''}, ...BRICK_SURFACES],

            color: '',
            surface: '',
            collection: '',
            subcollection: '',

            zoom: false,

            selection: [],
            comment: '',

            pageSize: 12,
            designs: [],
            loadedAll: false,
            searchOnFilterChange: true,
        };
    },

    computed: {
        ...mapGetters('authentication', {
            user: 'getUser',
            roles: 'getRoles',
        }),
        ...mapState('workflow', {
            workflow: 'boundInstance',
        }),
    },

    watch: {
        isVisible (value) {
            value && this.loadInspirations();
        },

        color () {
            this.searchOnFilterChange && this.startSearch();
        },

        surface () {
            this.searchOnFilterChange && this.startSearch();
        },
        collection () {
            if (this.subcollection !== '') {
                // subcollection watcher handelt starten met zoeken af
                this.subcollection = '';
            } else {
                this.searchOnFilterChange && this.startSearch();
            }

            this.updateSubcollectionList();
        },
        subcollection () {
            this.searchOnFilterChange && this.startSearch();
        },
    },

    mounted () {
        /** @var {Object} - Bevat de Firestore Query template voor paginering van de designs */
        this.designsPageTemplate = null;

        /** @var {Object} - Bevat de Firestore Query (inclusief cursor en limiet) voor paginering van de designs */
        this.designsPage = null;

        this.initiateInspirations();
        this.resetFilters();
        this.clearResults();
    },

    methods: {
        /**
         * Voeg de selectie toe aan het moodboard
         * TODO: ombouwen naar echte data
         */
        addSelectionToMoodboard () {
            const files = this.selection.map(option => createFileEntry(option));

            addFirestoreMoodboardEntry({refMoodboard: this.moodboard.$ref}, {
                heading: this.$t('inspiration.label_moodboard'),
                comment: this.comment,
                files,
                user: this.user,
            });

            this.isVisible = false;
        },

        /**
         * Wist alle zoekopdrachten en herstelt de lijst met resultaten naar begin-instellingen
         */
        resetSearch () {
            this.searchOnFilterChange = false;
            this.resetFilters();

            this.$nextTick(() => {
                this.searchOnFilterChange = true;
                this.startSearch();
            });
        },

        /**
         * Begin zoeken met een lege lijst
         */
        startSearch () {
            this.clearResults();
            this.applyFilters();
            this.loadInspirations();
        },

        /**
         * Leeg de huidige resutatenlijst
         */
        clearResults () {
            this.designs = [];
            this.loadedAll = false;
            this.designsPage = this.designsPageTemplate;
        },

        /**
         * Herstel query en bijbehorende filters naar de begininstellingen
         */
        resetFilters () {
            this.color = '';
            this.surface = '';
            this.collection = '';
            this.subcollection = '';
        },

        /**
         * Stel filters in op zoekresultaten
         */
        applyFilters () {
            if (this.color) {
                this.designsPage = this.designsPage.where('brickColors', 'array-contains', this.color.toLowerCase());
            }

            if (this.surface) {
                this.designsPage = this.designsPage.where('surface', '==', this.surface.toLowerCase());
            }

            if (this.collection) {
                this.designsPage = this.designsPage.where('collection', '==', this.collection.toLowerCase());
            }

            if (this.subcollection) {
                this.designsPage = this.designsPage.where('subcollection', '==', this.subcollection.toLowerCase());
            }
        },

        /**
         * Haal 1 pagina op met resultaten (na scrollen)
         * @return {Promise<void>}
         */
        async loadInspirations () {
            if (this.loadedAll) {
                return;
            }

            const documentSnapshots = await this.designsPage.get();

            // Maak designs op voor weergave
            this.designs = [...this.designs, ...documentSnapshots.docs.map(design => ({...design.data(), id: design.id, $snap: design}))];

            // Geef ieder bestand een uniek ID zodat ze individueel geselecteerd kunnen worden.
            this.designs.forEach(design => {
                design.files = design.files.map((file, index) => ({
                    id: design.id + index,
                    ...file,
                    title: file.title.replace(/\.(jpe?g|bmp|gif|png)$/, ''),
                }));
            });

            if (documentSnapshots.docs.length) {
                // Bepaal het laatste resultaat zodat we verder kunnen gaan vanaf dat punt na scrollen
                const commentsCursor = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                this.designsPage = this.designsPage.startAfter(commentsCursor);
            } else {
                this.loadedAll = true;
            }
        },

        /**
         * Stel objecten in voor infinite scroll & live update
         * @return {Promise<void>}
         */
        initiateInspirations () {
            // Stel de query in voor het ophalen van 1 "pagina" aan designs
            this.designsPageTemplate = refInspirations
                .orderBy('orderBy', 'asc')
                .limit(this.pageSize);

            this.designsPage = this.designsPageTemplate;
        },

        /**
         * Werk de lijst van subcollecties bij aan de hand van de gekozen collecties
         */
        updateSubcollectionList () {
            if (!this.collection) {
                this.subcollectionFilters = [this.subcollectionFilters[0]];
                return;
            }

            this.subcollectionFilters = [
                this.subcollectionFilters[0],
                ...BRICK_COLLECTIONS
                    // Zoek de collectie met huidige naam
                    .find(collection => collection.name === this.collection)
                    // Voeg daarvan de subcollecties toe aan de lijst
                    .subcollections,
            ];
        },
    },
};
</script>

<style scoped lang="scss">
.extra-select {
    display: grid;
    margin-top: -15px;
    margin-bottom: 10px;
    grid-template-columns: 1fr 5fr;

    .v-icon {
        transform: translateY(-17px);
    }
}

.color-list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 4px;
    margin-bottom: 4px;

    & > div {
        width: 100%;
        overflow: hidden;
    }
}
</style>
