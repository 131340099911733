<template>
    <v-col class="px-12 py-9" cols="12">
        <v-card dark class="px-8 py-4 mt-12 mb-4" outlined tile>
            <v-card-title class="tiemposText ml-0 pl-0">
                <v-icon x-large color="white" class="mr-5">mdi-account-group-outline</v-icon>
                <h1 class="text-h1 mb-2">{{ $t('moodboard.heading') }}</h1>
                {{ $t('workplace.explanation') }}
            </v-card-title>
        </v-card>

        <moodboard-list :moodboard="workplace" :user="user" :item-heading="$t('moodboard.label_virtual_workplace')">
            <template #actions="{moodboard, user}">
                <v-card flat color="transparent" class="pa-0">
                    <v-card-text class="tiemposText px-0 py-0">{{ $t('moodboard.heading_add')}}</v-card-text>
                    <v-card-text class="px-0 pb-0">
                        <moodboard-add-files-entry-dialog :moodboard="moodboard" :user="user" v-slot="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-3 mb-3 boxed-icon" tile elevation="0" :disabled="!moodboard.$ref">
                                {{ $t('moodboard.add_files') }}
                                <v-icon right>mdi-file-plus-outline</v-icon>
                            </v-btn>
                        </moodboard-add-files-entry-dialog>

                        <moodboard-add-links-entry-dialog :moodboard="moodboard" :user="user" v-slot="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-3 mb-3 boxed-icon" tile elevation="0" :disabled="!moodboard.$ref">
                                {{ $t('moodboard.add_links') }}
                                <v-icon right>mdi-link-plus</v-icon>
                            </v-btn>
                        </moodboard-add-links-entry-dialog>

                        <inspiration-picker-dialog :moodboard="moodboard" v-slot="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-3 mb-3 boxed-icon" tile outlined elevation="0">
                                {{ $t('inspiration.pick_a_design') }}
                                <v-icon right>mdi-eye-plus-outline</v-icon>
                            </v-btn>
                        </inspiration-picker-dialog>
                    </v-card-text>
                </v-card>
            </template>
        </moodboard-list>
    </v-col>
</template>

<script>
import MoodboardList from '@/components/MoodboardList';
import { mapGetters } from 'vuex';
import MoodboardAddFilesEntryDialog from '@/components/dialogs/MoodboardAddFilesEntryDialog';
import MoodboardAddLinksEntryDialog from '@/components/dialogs/MoodboardAddLinksEntryDialog';
import InspirationPickerDialog from '@/components/dialogs/InspirationPickerDialog';

export default {
    name: 'TheProjectWorkplace',

    components: {InspirationPickerDialog, MoodboardAddLinksEntryDialog, MoodboardAddFilesEntryDialog, MoodboardList},

    computed: {
        ...mapGetters('authentication', {
            user: 'getUser',
        }),
        ...mapGetters('project', {
            workplace: 'workplace',
        }),
    },
};
</script>

<style scoped>

</style>
